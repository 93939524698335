.request-item {
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: 12px 8px;
  border-bottom: 1px solid #dcdcdc;
}

.request-details {
  display: flex;
  width: 307px;
  padding: 8px 12px;
  align-items: center;
}

.request-icon-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.request-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffcc00;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-letter {
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.request-info {
  display: flex;
  flex-direction: column;
}

.request-name {
  color: #0c0c0c;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.request-reference {
  color: var(--Color-White-smoke-400, #797979);
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.request-type {
  display: flex;
  width: inherit;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}
.request-amount {
  display: flex;
  padding: 0px 12px;
  justify-content: flex-end;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
}

.request-type-purchase {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.request-type-liquidate {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.request-amount {
  color: var(--Text-Primary, #121212);
  text-align: right;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
