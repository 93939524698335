.react-datepicker {
    border-radius: 24px;
    background: var(--Background-Layer, #FAFAFA);
  }
  
  .react-datepicker__day--disabled,
  .disabled-date {
    color: #C6C6C6;
    text-align: center;
    font-family: Lato;
    font-size: 13.767px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.356px;
  }

  .react-datepicker__day--enabled,
  .enabled-date {
    color: var(--Text-Primary, #121212);
    text-align: center;
    font-family: Lato;
    font-size: 13.767px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.356px; 
  }
  
  .react-datepicker__header {
    background: rgb(255, 255, 255);
    border: none;
    font-weight: bold;
  }
  
  .react-datepicker__day {
    border-radius: 50%;
    font-weight: 500;
  }
  
  .react-datepicker__navigation--next 

  .react-datepicker__navigation--previous {
    top: 40px;

  }

  
  .react-datepicker__current-month {
    color: var(--Text-Primary, #121212);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  
    