.transaction-item {
  display: grid;
  grid-template-columns: 25vw 17vw 17vw 17vw; /* Define columns for transaction, date, type, and amount */
  align-items: center;
  width: 100%;
  padding: 12px 8px;
  /* border-bottom: 1px solid #dcdcdc; */
}

.transaction-details {
  display: flex;
  align-items: center;
}

.transaction-fund {
  color: var(--grey-grey-900, #0c0c0c);

  /* Body/Small - SB */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
}

.transaction-info {
  display: flex;
  flex-direction: column;
}

.transaction-reference {
  color: var(--Text-Primary, #121212);

  /* Body/Small */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.transaction-reference1{
  color: var(--grey-grey-600, var(--Color-White-smoke-400, #797979));

/* Body/overline */
font-family: Lato;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 14px */
}

.transaction-type {
  color: var(--Text-Primary, #121212);

  /* Body/Small */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.transaction-amount {
  color: #121212;
  font-family: Lato;
  justify-content: left;
  font-size: 14px;
  font-weight: 400;
}


