.btn-custom-size {
    height: 38px;
    width: 69px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 0; 
  }
  
  .btn-custom-size-continue {
    display: flex;
    height: 52px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
  
  
  .input-container {
    position: relative;
  }
  
  .input-container input {
    background-color: #f2f2f2;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: regular;
  }
  
  
  .verify-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 12px;
  }

  .resend-button {
    height: 32px;
    width: 151px;
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    border-radius: 12px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .form-control {
    width: 523px;
    height: 56px;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: black!important;
  }

  
  .custom-file-input input[type="file"] {
    width: calc(100% - 0px); 
  }
  
  
    .custom-file-input {
      
    height: 56px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    align-self: auto;
    border: 1px solid #ced4da;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin-bottom: 24px;
    }
    
    
    .custom-file-input1 {
        position: relative;
        height: 56px;
        padding: 16px;
        justify-content: center;
        align-items: center;
        align-self: auto;
        border: 1px solid #ced4da;
        background-color: #f2f2f2;
        border-radius: 10px;
        margin-bottom: 32px;
      }

      .custom-file-input2 {
      
        height: 56px;
        width: 640px;
        padding: 16px;
        justify-content: center;
        align-items: center;
        align-self: auto;
        border: 1px solid #ced4da;
        background-color: #f2f2f2;
        border-radius: 10px;
        margin-bottom: 24px;
        }
    
  .upload-btn {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;

  }
  
  .upload-btn img {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 50%;
    margin-left: auto; 
    transform: translateY(-50%);
  }
  
  .upload-btn span {
    margin-right: 16px;
    flex-grow: 1;
    color: #000000;
    text-align: left;
  }
  
  .form-control-file {
    display: none;
  }
  
  .input-container {
    width: 100%;
  }

  
  .custom-file-input input[type="file"] {
    display: none;
  }
  
  
/* Hide scrollbar for WebKit browsers */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  
  
