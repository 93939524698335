/* customToast.css */

.custom-toast {
  background-color: #222 !important; /* Dark background */
  color: #fff !important; /* White text */
  border-radius: 8px !important; /* Rounded corners */
  font-family: 'Lato'; /* Custom font */
  font-size: 17px !important;
  display: flex;
  line-height: 22px;
  align-items: center;
 
  /* white-space: nowrap; */
  

}

.custom-toast-body {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 10px;
  font-family: 'Lato'; /* Custom font */
  font-size: 17px !important;
  gap: 30px !important;
 

}

.custom-toast-icon {
  background-color: #4FCA90 !important; 
  color: black !important; 
  border-radius: 50%;
  width: 21px;  /* Relative size based on font size */
  height: 21px; 
  display: flex;
  margin-inline-end: 16px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'Lato';
}

.custom-toast-close-button {
  color: #ffffff !important;
}
.custom-toast-progress {
  background-color: #4FCA90 !important; /* New color for the progress bar */
}

.Toastify__toast-icon {
  margin-right: 20px; /* Add margin to space out from text */
  display: flex;
}

